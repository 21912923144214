<template>
  <div class="overseas">
    <image-box :src="require('../../assets/image/overseas/overseas_1.jpg')" :height="749"></image-box>
    <image-box :src="require('../../assets/image/overseas/overseas_2.jpg')" :height="845"></image-box>
    <image-box :src="require('../../assets/image/overseas/overseas_3.jpg')" :height="978"></image-box>
    <image-box :src="require('../../assets/image/overseas/overseas_4.jpg')" :height="979"></image-box>
    <image-box :src="require('../../assets/image/overseas/overseas_5.jpg')" :height="665"></image-box>
  </div>
</template>

<script>
  import ImageBox from "../../components/ImageBox/ImageBox";
  export default {
    name: "about",
    components: {
      "image-box":ImageBox
    },
    data() {
      return {
      }
    },
    created() {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @media screen and (min-width: $minDeviceWidth_pc) {
    @import "overseas_pc";
  }

  @media screen and (max-width: $minDeviceWidth_pc) {
    @import "overseas_mobile";
  }
</style>
